
@import "variables.less";
@import "bootstrap.less";
@import "../../common/utils.less";
@import "../../common/flags-small.less";
@import "../../common/flags.less";
@import "elements/popup.less";
@import "elements/messages.less";

body {
	overflow-y: scroll;
	margin: 0;
}

a {
	text-decoration: underline;
}

blockquote {
	border-color: #000;
}

#header {
	color: #fff;
	font-size: 18px;
	background: #0F4BFF;
	border-bottom: 2px solid #f0b500;
	overflow: hidden;
	header {
		border-bottom: 1px solid #000090;
		overflow: hidden;
	}
	.brand {
		margin-left: 5px;
		margin-right: 20px;
		font-weight: bold;
	}
	#language-switcher {
		cursor: pointer;
		margin: 0 5px;
		position: absolute;
		right: 0;
		top: 0;
		.flag {
			margin: -12px 0;
		}
	}
}

#page {
	margin: 0 auto 10px;
	max-width: 930px;
	padding: 0 10px;
}

#language-select {
	overflow: hidden;
	clear: both;
	background: #fff;
	border: 1px solid #000;
	margin-top: 10px;
	a {
		padding-left: 2px;
		display: block;
		float: left;
		width: 33.3333%;
		text-decoration: none;
		font-weight: 700;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		.flag {
			vertical-align: middle;
		}
	}
}

#footer {
	text-align: center;
	margin: 20px 0 10px;
}

@media(min-width: 480px) {
	#language-select {
		a {
			width: 25%;
		}
	}
}

@media(min-width: 640px) {
	#header #language-switcher {
		float: right;
		position: static;
		right: auto;
		top: auto;
	}
	#language-select {
		a {
			width: 20%;
		}
	}
}
